@import '../../../assets/scss/variables.scss';

.service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  max-width: 22.18rem;
  min-width: 22.18rem;
  height: 18.25rem;
  padding: 1.35rem 1.72rem;
  margin: 0 0 0 $padding;
  position: relative;

  border-radius: 1.37rem;

  &:first-child {
    margin: 0;

    background: $service1-bg;

    & h3, & .position {
      color: $service1-main;
    }

    & path {
      stroke: $service1-main;
    }
  }

  &:nth-child(2) {
    background: $service2-bg;

    & h3, & .position {
      color: $service2-main;
    }

    & path {
      stroke: $service2-main;
    }
  }

  &:nth-child(3) {
    background: $service3-bg;

    & img {
      width: 12.5rem;
      width: 9.5rem;
    }

    & h3, & .position {
      color: $service3-main;
    }

    & path {
      stroke: $service3-main;
    }
  }

  &:nth-child(4) {
    background: $service4-bg;

    & h3, & .position {
      color: $service4-main;
    }

    & path {
      stroke: $service4-main;
    }
  }
}

// Top
.top {
  display: grid;
  grid-template-columns: 70% auto;
  justify-content: space-between;
  align-items: center;

  & h3 {
    font-size: 1.178rem;
    font-family: $general-font;
    font-weight: 400;
    line-height: 1.6rem;
  }
}

// CTA - arrow
.cta {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3.5rem;
  height: 3.5rem;

  background: $primary;
  border-radius: 50%;
}

.cta-arrow {
  margin: -1px -3.5px 0 0;
  width: 1.05rem;
}

// Middle - points
.service {
  & ul {
    margin: 0.3rem 0 0 0.81rem;
  }

  & li {
    margin: 0 0 0 0;

    list-style-type: '•  ';
    color: $service-text;
    font-weight: 300;
    font-size: 0.975rem;
  }
}

// Position
.position {
  font-family: $general-font;
  font-weight: 300;
  font-size: 0.92rem;
}

// Illustration
.illustration {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 13.5rem;
  width: 10.5rem;

  border-radius: 45px 0px 22px;
}

// Desktop view
@media all and (min-width: 1025px) {
  .service {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    width: 100%;
    max-width: 31rem;
    min-width: 31rem;
    height: 24.25rem;
    padding: 1.7rem 2rem;
    margin: 0 0 0 $padding;
    position: relative;
  
    border-radius: 1.37rem;
  
    &:first-child {
      margin: 0;
  
      background: $service1-bg;
  
      & h3, & .position {
        color: $service1-main;
      }
  
      & path {
        stroke: $service1-main;
      }
    }
  
    &:nth-child(2) {
      background: $service2-bg;
  
      & h3, & .position {
        color: $service2-main;
      }
  
      & path {
        stroke: $service2-main;
      }
    }
  
    &:nth-child(3) {
      background: $service3-bg;
  
      & img {
        width: 15.5rem;
        width: 12.5rem;
      }
  
      & h3, & .position {
        color: $service3-main;
      }
  
      & path {
        stroke: $service3-main;
      }
    }
  
    &:nth-child(4) {
      background: $service4-bg;
  
      & h3, & .position {
        color: $service4-main;
      }
  
      & path {
        stroke: $service4-main;
      }
    }
  }
  
  // Top
  .top {
    display: grid;
    grid-template-columns: 70% auto;
    justify-content: space-between;
    align-items: center;
  
    & h3 {
      font-size: 1.72rem;
      font-family: $general-font;
      font-weight: 400;
      line-height: 2.1rem;
    }
  }
  
  // CTA - arrow
  .cta {
    display: flex;
    align-items: center;
    justify-content: center;
  
    width: 3.5rem;
    height: 3.5rem;
  
    background: $primary;
    border-radius: 50%;
  }
  
  .cta-arrow {
    margin: -1px -3.5px 0 0;
    width: 1.05rem;
  }
  
  // Middle - points
  .service {
    & ul {
      margin: 0.6rem 0 0 1.1rem;
    }
  
    & li {
      margin: 0 0 0 0;
  
      list-style-type: '•  ';
      color: $service-text;
      font-weight: 300;
      font-size: 1.18rem;
    }
  }
  
  // Position
  .position {
    font-family: $general-font;
    font-weight: 300;
    font-size: 0.92rem;
  }
  
  // Illustration
  .illustration {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 18.5rem;
    width: 15.5rem;
  
    border-radius: 45px 0px 22px;
  }

}