@import '../../assets/scss/variables.scss';

.process {
  padding: 5.25rem $padding 0 $padding;

  & h3 {
    @include sub;
  }

  & h2 {
    margin: 0 3rem 0 0;

    @include titles;
  }

  & p {
    margin: 1.15rem 0 0 0;

    @include text;
  }
}

// Bar
.bar {
  margin: 2.25rem 0 0 0;

  & ul {
    display: flex;

    overflow-x: scroll;
    white-space: nowrap;
    padding-bottom: 0.45rem;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    
    &::-webkit-scrollbar:horizontal {
      height: 7px;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #c9c9c9;
      border-radius: 10px;
      border: 2px solid #ffffff;
    }

  }
}

.btn {
  padding: 0.5rem 1.43rem;

  font-size: 0.95rem;
  color: $text-darker;
  list-style-type: none;
  font-weight: 500;
  background: none;
  border-radius: 3rem;
  transition: 200ms ease;
}

.active {
  background: $accent;
  color: $btn-text;
}

// Details
.detail {
  margin: 2.5rem 0 0 0;

  & img {
    width: 100%;

    box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
  }

  & h4 {
    margin: 2.2rem 0 -0.7rem 0;

    font-size: 1.2rem;
    font-weight: 500;
    color: $title;
    font-family: $general-font;
    text-decoration: underline;
  }
}

// Desktop view
@media all and (min-width: 1025px) {
  .cont {
    @include center;
  }

  .process {
    width: $width;
    padding: 8rem $padding 0 $padding;
  
    & h3 {
      @include sub2;
    }
  
    & h2 {
      margin: 0 3rem 0 0;
  
      @include titles2;
    }
  
    & p {
      margin: 1.15rem 30% 0 0;
  
      @include text2;
    }
  }
  
  // Bar
  .bar {
    margin: 2.25rem 0 6rem 0;
  
    & ul {
      display: flex;
  
      overflow-x: scroll;
      white-space: nowrap;
      padding-bottom: 0.45rem;
  
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }
      
      &::-webkit-scrollbar:horizontal {
        height: 7px;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: #c9c9c9;
        border-radius: 10px;
        border: 2px solid #ffffff;
      }
  
    }
  }
  
  .btn {
    padding: 0.5rem 1.43rem;
  
    font-size: 0.95rem;
    color: $text-darker;
    list-style-type: none;
    font-weight: 500;
    background: none;
    border-radius: 3rem;
    transition: 200ms ease;
    user-select: none;
    cursor: pointer;

    &:hover {
      color: $text2;
    }
  }
  
  .active {
    background: $accent;
    color: $btn-text;
  }
  
  // Details
  .detail {
    display: grid;
    grid-template-columns: 42% 58%;
    margin: 2.5rem 0 0 0;
  
    & img {
      width: 44rem;
      height: 24.5rem;
 
      object-fit: cover;
      box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
    }
  
    & h4 {
      margin: 0rem 0 -0.7rem 0;
  
      font-size: 1.2rem;
      font-weight: 500;
      color: $title;
      font-family: $general-font;
      text-decoration: underline;
    }
  }

}