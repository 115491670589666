$general-font: 'Poppins', sans-serif;
$header-font: 'Raleway', sans-serif;

// Colors - main
$accent: #133a75;
$accent-hover: #184283;
$accent2: #64a2ff;
$primary: #fff;
$second: #061a2f;
$text: #4d5d6d;
$text2: #708397;
$title-lighter: #0a2540;
$title: #051a2e;
$primary-second: #f6f9fc;
$text-darker: #4b5661;

// Other
$primary-darker: #ebeaea;
$btn-text: #fff;
$separation-border: #e7e7e6;
$separation-border-dark: #d4d4d4;
$input-border: #99a19c;
$accent2-title: #fff;
$accent2-text: #c4c9cd;
$primary-hover: #eeeeee;
$historical: #baa173;

// Services
$service1-bg: #fbf4f2;
$service1-main: #ea8f78;

$service2-bg: #eef2f3;
$service2-main: #315772;

$service3-bg: #f4f1eb;
$service3-main: #71643c;

$service4-bg: #f4f9ff;
$service4-main: #678ef4;

$service5-bg: #f9f5ff;
$service5-main: #ab79f8;
$service5-cta: #7b23ff;
$service5-sub: #cb90fa;
$service5-text: #675b7a;

$service-text: #6e7072;

// Form
$separation-success: #9bf694;
$separation-error: #fa8585;
$error: #ff2d2d;
$success: rgb(88, 189, 81);
$success-bg: #49b542;
$processing: rgb(140, 140, 140);
$form-bg: #fff;
$form-title: #181d33;
$form-outline: #1976d2;

$padding: 1.1rem;
$form-padding: 2.4rem;
$padding2: 2.4rem;
$width: 1268px;
$height: 646px;

@mixin titles {
  font-size: 1.7rem;
  font-weight: 700;
  letter-spacing: -1px;
  color: $title;
  line-height: 2.1rem;
}

@mixin sub {
  font-size: 0.92rem;
  color: $accent;
  font-family: $general-font;
  font-weight: 500;
}

@mixin text {
  font-size: 0.994rem;
  font-weight: 300;
  color: $text;
}

@mixin center {
  display: flex;
  justify-content: center;
  position: relative;

  width: 100%;
}

// DESKTOP VALUES
@mixin titles2 {
  font-size: 2.1rem;
  font-weight: 700;
  letter-spacing: -1px;
  color: $title;
  line-height: 2.78rem;
}

@mixin sub2 {
  font-size: 1.1rem;
  color: $accent;
  font-family: $general-font;
  font-weight: 500;
}

@mixin text2 {
  font-size: 1.3rem;
  font-weight: 300;
  color: $text2;
}
