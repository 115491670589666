@import '../../assets/scss/variables.scss';

.wwd {
  width: 100%;
  transform: skewY(-8.1deg);
  margin: -2rem 0 0 0;
  padding: 7rem $padding 10rem $padding;

  background: #0C2946;

  & h3 {
    @include sub;

    color: $accent2;
  }

  & h2 {
    @include titles;

    margin: 0.35rem 2rem 0 0;

    color: $accent2-title;
    font-weight: 500;
    font-size: 1.82rem;
  }

  & p {
    @include text;
    
    margin: 1.7rem 2rem 0 0;

    color: $accent2-text;
    font-size: 0.94rem;
    line-height: 1.5rem;
  }
}

// Reverse the skewing effect for text
.inner {
  transform: skewY(8.1deg);
}

.images {
  margin: 4.2rem 0 0 0;
  position: relative;

  & img {
    border-radius: 0.6rem;
    filter: drop-shadow(0px 4px 157px rgba(255, 255, 255, 0.13));

    &:first-child {
      width: 82%;
    }

    &:last-child {
      width: 82%;
      position: absolute;
      right: 0;
      top: 2.25rem;
    }
  }
}

@media all and (min-width: 1025px) {
  .cont {
    @include center;

    transform: skewY(-2.9deg);
    margin: -2rem 0 0 0;
    padding: 11.6rem $padding2 11.6rem $padding2;
  
    background: #0C2946;
  }

  .wwd {
    width: $width;
    transform: initial;
    margin: initial;
    padding: initial;
  
    background: #0C2946;
  
    & h3 {
      @include sub2;
  
      color: $accent2;
      font-size: 1rem
    }
  
    & h2 {
      @include titles2;
  
      margin: 0.35rem 10rem 0 0;
  
      color: $accent2-title;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.4rem
    }
  
    & p {
      @include text2;
      
      margin: 1.7rem 10rem 0 0;
  
      color: $accent2-text;
      font-size: 0.94rem;
      line-height: 1.5rem;
    }
  }
  
  // Reverse the skewing effect for text
  .inner {
    display: grid;
    grid-template-columns: 45% 50%;
    justify-content: center;
    align-items: center;
    transform: skewY(3deg);
  }
  
  .images {
    margin: -1.9rem 0 0 0;
    position: relative;
  
    & img {
      border-radius: 0.6rem;
      filter: drop-shadow(0px 4px 157px rgba(255, 255, 255, 0.13));
  
      &:first-child {
        width: 75%;
      }
  
      &:last-child {
        width: 75%;
        position: absolute;
        right: 0;
        top: 4rem;
      }
    }
  }

  // Reversed
  .wwd-reversed {
    & .inner {
      display: grid;
      grid-template-columns: 48.5% 48.5%;
      justify-content: space-between;
      align-items: center;
      transform: skewY(3deg);
    }

    & .images {
      margin: -1.9rem 0 0 0;
      position: relative;
    
      & img {
        border-radius: 0.6rem;
        filter: drop-shadow(0px 4px 157px rgba(255, 255, 255, 0.13));
    
        &:first-child {
          width: 75%;
        }
    
        &:last-child {
          width: 75%;
          position: absolute;
          right: 3.2rem;
          top: 4.2rem;
        }
      }
    }
  }

}