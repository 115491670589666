@import '../../assets/scss/variables.scss';

.footer {
  width: 100%;
  padding: 7.63rem $padding 0 $padding;
}

// Top section - prompt for JUBEFA
.top {
  padding: 2.8rem 0;
  position: relative;

  // Grey lines - separation
  &::before {
    content: '';
    position: absolute;
    width: 39.5%;
    height: 2px;
    background: $separation-border;
    left: -$padding;
    top: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: $separation-border;
    left: -$padding;
    bottom: 0;
  }
}

// Top - text
.top {
  & h3 {
    margin: 0 20% 0 0;

    color: $title;
    font-size: 1.3rem;
    font-weight: 600;
  }
}

// Main
.main {
  padding: $padding 0;

  & a {
    text-decoration: none;
    color: $text;
  }

  & li {
    margin: 0.1rem 0 0 0;

    list-style-type: none;
    font-size: 1.05rem;
    font-weight: 400;
  }
}

// Links
.links {
  & li {
    color: $text;
    list-style-type: none;
  }
}

// Info
.info {
  margin: 3rem 0 0 0;

  & li {
    color: $text;
  }
}

// Info
.newsletter {
  margin: 3.2rem 0 0 0;

  & h5 {
    font-size: 1.05rem;
    font-weight: 400;
    color: $text;
  }
}

// Form
.form {
  display: flex;
  align-items: center;

  margin: 0.6rem 0 0 0;

  & input {
    width: 100%;
    height: 3rem;
    padding: 0 1.25rem;

    border-radius: 3rem;
    border: 1.5px solid $input-border;
    background: transparent;
    font-size: 0.9rem;
    color: $text;
    font-family: $general-font;
    font-weight: 400;
    outline-color: $accent;
  }

  & button {
    min-height: 3rem;
    min-width: 3rem;
    margin: 0 0 0 0.6rem;

    border-radius: 50%;
    background: $accent;
    border: none;
    transition: ease 300ms;

    // Check
    & img {
      width: 1.31rem;
      height: 1.31rem;
      margin: 5px 0 0 0;
    }
  }
}

// Footer title
.title {
  margin: 0 0 0.74rem 0;

  display: flex;
  align-items: center;

  & a {
    margin: 0 0 0 0.25rem;

    font-size: 1.13rem;
    font-weight: 500;
    color: $accent;
    text-decoration: none;
  }

  & img {
    width: 2.85rem;
    height: 2.85rem;

    filter: brightness(80%);
  }
}

// Copyright
.copyright {
  margin: 10rem 0 0 0;

  font-size: 1.07rem;
  color: $text;
  font-weight: 400;
  line-height: 1.48rem;
}

// Badges
.badges {
  margin: 1.6rem 0 0 0;

  & img {
    width: 13.5rem;
  }

  & a {
    &:first-child img {
      width: 8rem;
    }

    &:nth-child(3) img {
      width: 7rem;
    }
  }
}

// ERROR
.error {
  margin: 0.5rem 0 0 0;

  font-size: 0.88rem;
  color: $error;
}

// Disabled btn
.btn-processing {
  background: $processing !important;
}

// Success
.success {
  margin: 0.5rem 0 0 0;

  font-size: 0.88rem;
  color: $success;
}

@media all and (min-width: 1025px) {
  .footer {
    width: 100%;
    padding: 7.63rem $padding2 0 $padding2;
  }

  // Top section - prompt for JUBEFA
  .top-inner {
    width: $width;
    padding: 2.8rem 1.88rem;
  }

  .top {
    @include center;

    padding: initial;
    position: relative;

    // Grey lines - separation
    &::before {
      content: '';
      position: absolute;
      width: 34%;
      height: 2px;
      background: $separation-border;
      left: -$padding;
      top: 0;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: $separation-border;
      left: -$padding;
      bottom: 0;
    }
  }

  // Top - text
  .top {
    & a {
      margin: 1.6rem 0 0 0 !important;
    }

    & h3 {
      margin: 0 75% 0 0;

      font-size: 1.45rem;
      font-weight: 600;
      line-height: 1.78rem;

      & span {
        display: block;
      }
    }
  }

  // Main
  .cont {
    @include center;
  }

  .main-content {
    display: grid;
    grid-template-columns: 23.5% 33% 28%;
  }

  .main {
    width: $width;
    padding: $padding + 0.6rem 1.88rem;

    & a {
      width: fit-content;

      text-decoration: none;
      color: $text;
    }

    & li {
      margin: 0.4rem 0 0 0;
      width: fit-content;

      list-style-type: none;
      font-size: 1rem;
      font-weight: 400;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  // Links
  .links {
    & li {
      list-style-type: none;
    }

    & a:hover {
      text-decoration: underline;
    }
  }

  // Info
  .info {
    margin: 0rem 0 0 0;

    color: $text;
  }

  // Info
  .newsletter {
    margin: 0rem 0 0 0;

    & h5 {
      font-size: 1.05rem;
      font-weight: 400;
      color: $text;
    }
  }

  // Form
  .form {
    display: flex;
    align-items: center;

    margin: 0.25rem 0 0 0;

    & input {
      width: 100%;
      height: 2.68rem;
      padding: 0 1.25rem;

      border-radius: 3rem;
      border: 1.5px solid $input-border;
      background: transparent;
      font-size: 0.83rem;
      color: $text;
      font-family: $general-font;
      font-weight: 400;
      outline-color: $accent;
    }

    & button {
      min-height: 3rem;
      min-width: 3rem;
      margin: 0 0 0 0.6rem;

      border-radius: 50%;
      background: $accent;
      border: none;
      transition: 300ms ease;

      &:hover img {
        transform: rotate(360deg);
      }

      // Check
      & img {
        width: 1.31rem;
        height: 1.31rem;
        margin: 5px 0 0 0;
        position: relative;
        transition: 300ms ease;
      }
    }
  }

  // Footer title
  .title {
    margin: 0 0 0.83rem 0;

    display: flex;
    align-items: center;

    & a {
      margin: 0 0 0 0.35rem;

      font-size: 1.1rem;
      font-weight: 500;
      color: $accent;
      transition: 250ms ease;
      text-decoration: none;
    }

    & img {
      width: 2.5rem;
      height: 2.5rem;

      filter: brightness(80%);
    }
  }

  // Copyright
  .copyright {
    margin: 10rem 0 0 0;

    font-size: 1.07rem;
    color: $text;
    font-weight: 400;
    line-height: 1.48rem;
  }

  // ERROR
  .error {
    margin: 0.2rem 0 0 0;

    font-size: 0.88rem;
    color: $error;
  }

  // Success
  .success {
    margin: 0.6rem 0 0 0;

    font-size: 0.88rem;
    line-height: 1.1rem;
    color: $success;
  }

  // Disabled btn
  .btn-processing {
    background: $processing !important;
  }

  // Badges
  .badges {
    margin: 2.3rem 0 0 0;

    & img {
      width: 13.5rem;
      margin: 0 0 0 1.4rem;
    }

    & a {
      &:first-child img {
        width: 8rem;
        margin: initial;
      }

      &:nth-child(3) img {
        width: 7rem;
      }
    }
  }
}
