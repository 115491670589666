@import '../../../assets/scss/variables.scss';

.cost {
  padding: 4.5rem $padding 10rem $padding;
  margin: 6rem 0 0 0;

  background: $primary-second;

  & h3 {
    @include sub;
  }

  & h2 {
    @include titles;
  
    margin: 0 7rem 0 0;
  }

  & p {
    @include text;

    margin: 1.6rem 0 0 0;
  }
}

.illustration {
  margin: 2.83rem 0 0 0;
  position: relative;

  & img {
    box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);

    &:first-child {
      width: 100%;
    }

    &:last-child {
      width: 37%;
      top: 3rem;
      left: 2rem;
      position: absolute;
      border-radius: 0.7rem;
    }
  }
}

@media all and (min-width: 1025px) {
  .cont {
    @include center;

    margin: 8rem 0 0 0;

    background: $primary-second;
  }

  .cost {
    display: grid;
    grid-template-columns: 45% 50%;
    justify-content: space-between;

    width: $width;
    padding: 4.8rem $padding2 13rem $padding2;
  
    background: initial;
  
    & h3 {
      @include sub2;
    }
  
    & h2 {
      @include titles2;
    
      margin: 0 7rem 0 0;
    }
  
    & p {
      @include text2;
  
      margin: 1.6rem 0 0 0;
    }
  }
  
  .illustration {
    margin: -1rem 0 0 0;
    position: relative;
  
    & img {
      box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
  
      &:first-child {
        width: 100%;
      }
  
      &:last-child {
        width: 37%;
        top: 4rem;
        left: 4rem;
        position: absolute;
        border-radius: 0.7rem;
      }
    }
  }

}