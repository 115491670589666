@import '../../../assets/scss/variables.scss';

.landing {
  padding: 4.2rem + 4.6rem $padding 1.89rem $padding;

  // Sub
  & h3 {
    margin: 2.5rem 0 0 0;

    @include sub;
    color: $historical;
  }

  // Text
  & p {
    @include text;
    margin: 1.2rem 0 0.4rem 0;
    font-size: 1.15rem;
  }

  // Title
  & h1 {
    @include titles;
  }

  // IMG
  & img {
    width: 100%;
  }
}

@media all and (min-width: 1024px) {
  .cont {
    @include center;
  }

  .landing {
    display: grid;
    grid-template-columns: 48% 45%;
    justify-content: space-between;
    width: $width;
    padding: 8rem + 4.6rem $padding2 1.89rem $padding2;

    // Sub
    & h3 {
      margin: 2.5rem 0 0 0;

      @include sub2;
      color: $historical;
    }

    // Text
    & p {
      @include text2;
      margin: 1.2rem 0 0.4rem 0;
      font-size: 1.15rem;
    }

    // Title
    & h1 {
      @include titles2;
    }

    // IMG
    & img {
      width: 100%;
    }
  }
}
