@import '../../../assets/scss/variables.scss';

// Menu
.menu {
  width: 0;
  height: 0;
  position: fixed;
  top: -5rem;
  right: -5rem;
  z-index: 10000;

  background: $primary-darker;
  border-radius: 50%;
  transition: all 400ms;
}

// Open state of the menu
.menu-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  padding: 1rem 1rem 1.8rem 1rem;
  height: 100%;
  
  opacity: 0;
  transition: 300ms ease-in;
  transition-delay: -1s;
}

.menu-content-visible {
  opacity: 100%;

  transition: 300ms ease-in;
  transition-delay: 0.4s;
}

.open {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  top: 0;
  right: 0;

  border-radius: 0;
}

// Content
// Top
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  // Close
  & h5 {
    font-size: 0.96rem;
    font-weight: 600;
    text-decoration: underline;
    color: $title;
  }
}

.title {
  display: flex;
  align-items: center;

  & h2 {
    margin: 0 0 0 0.12rem;

    font-family: $general-font;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.33rem;
    color: $accent;
  }

  & img {
    margin: 0 0 0 -5px;
  }
}

// Links
.links {
  margin: 1.8rem 0 0 0;

  & li {
    margin: 0.32rem 0 0 0;

    font-size: 1.25rem;
    list-style-type: none;color: $title;
  }

  & a {
    color: $title;
    text-decoration: none;
  }
}

// Bottom
.bottom {
  & li {
    margin: 0.32rem 0 0 0;
    position: relative;
    width: fit-content;

    font-size: 1.25rem;
    list-style-type: none;

    // Number
    &:first-child::before {
      content: '';
      position: absolute;

      width: 3rem;
      height: 2px;
      bottom: -1.5px;
      left: 0.9rem;

      background: $title;
      border-radius: 3rem;
    }

    & a {
      color: $title;
      text-decoration: none;
    }

    // CTA
    &:last-child {
      display: flex;
      align-items: center;

      padding: 0.684rem 1.9rem;
      margin: 1.1rem 0 0;
      
      border-radius: 50px;
      font-weight: 500;
      font-size: 1.05rem;
      background: $accent;
      
      & img {
        margin: 0 0 0 0.67rem;
        width: 0.56rem;
      }

      & a {
        color: $btn-text;
        text-decoration: none;
      }
    }
  }
}