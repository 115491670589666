@import '../../../assets/scss/variables.scss';

.landing {
  padding: 4.6rem + 4.6rem $padding 1.89rem $padding;

  // Subtitle
  & h3 {
    margin: 3rem 0 4px 0;

    font-size: 0.95rem;
    font-weight: 400;
    color: $accent;
    font-family: $general-font;
  }

  // Title
  & h1 {
    margin: 0 10% 0 0;

    font-size: 1.86rem;
    font-weight: 700;
    color: $title-lighter;
    letter-spacing: -1.2px;
  }
}

// Images
.images {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  & img {
    width: 18.25rem;
    height: 11.9rem;

    object-fit: cover;
    border-radius: 1.3rem;

    &:last-child {
      align-self: flex-start;

      margin: -4rem 0 0 0;
      width: 16.8rem;
      height: 12.5rem;
    }
  }
}

// Arrow
.arrow {
  margin: 1rem 0 0 11.5rem + 0.3rem;
}

@media all and (min-width: 1024px) {
  .cont {
    @include center;
  }

  .landing {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: flex-end;
    justify-content: flex-end;

    width: $width;
    padding: 4.6rem + 4.6rem $padding2 1.89rem $padding2 + 5rem;

    // Subtitle
    & h3 {
      margin: 0 0 4px 0;

      font-size: 0.95rem;
      font-weight: 400;
      color: $accent;
      font-family: $general-font;
    }

    // Title
    & h1 {
      margin: 0 25% 0 0;

      font-size: 2rem;
      font-weight: 700;
      color: $title-lighter;
      letter-spacing: -1.2px;
    }
  }

  .content {
    margin: 0 0 0 3rem;
  }

  // Images
  .images {
    display: initial;
    width: initial;

    & img {
      width: 28.7rem;
      height: 18.9rem;

      object-fit: cover;
      border-radius: 1.3rem;

      &:last-child {
        margin: -12rem 0 0 9.25rem;
        width: 25.12rem;
        height: 18.1rem;
      }
    }
  }
}
