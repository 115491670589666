@import '../../../assets/scss/variables.scss';

// Main
.main {
  & ul {
    margin: 0 0 0 2.5rem;
  }

  & li {
    display: inline;

    position: relative;
    

    font-size: 0.82rem;
    font-weight: 400;
    color: $title;

    // Decorative line
    // &::before {
    //   content: '';
    //   width: 3.75rem;
    //   height: 3px;
    //   position: absolute;
    //   left: 0;
    //   bottom: -2px;

    //   border-radius: 50px;
    //   background: $title;
    // }

    &:last-child, &:nth-child(2) {
      padding: 0.6rem 0.89rem;

      transition: 300ms ease;
      cursor: pointer;
      border-radius: 3px;

      &:hover {
        background: $primary-hover;
      }
    }
  }

  & a {
    padding: 0.6rem 0.89rem;

    color: $title;
    text-decoration: none;
    transition: 300ms ease;
    border-radius: 3px;

    &:hover {
      background: $primary-hover;
    }
  }
}

// Aside info
.aside { 
  display: flex;
  align-items: center;

  & li {
    display: inline;

    position: relative;
    margin: 0 1.25rem 0 0;

    font-size: 0.82rem;
    font-weight: 400;
    color: $title;      
    cursor: pointer;
    transition: 300ms ease;

    &:nth-child(2) {
      &:hover {
        color: $accent;
      }
    }

    &:last-child {
      padding: 0.62rem 1.42rem;
      margin: 0 0 0 0;

      font-size: 0.82rem;
      border-radius: 50px;
      color: $primary;
      font-weight: 400;
      background: $title;

      &:hover {
        background: $accent-hover;
      }
    }
  }
}

// Language
.language-text {
  user-select: none;

  &::before {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: -0.2rem;

    border-radius: 50px;
    background: $title;
  }

  &:hover {
    color: $accent;
  }
}

// Dropdown
.dropdown {
  display: flex;
  flex-direction: column;

  position: absolute;
  width: 9.25rem;
  height: 5.25rem;
  margin: 0.75rem 0 0 0;
  z-index: 501;

  background: $primary;
  cursor: initial;
  box-shadow: -5px 5px 27px rgb(0, 0, 0, 0.12);
  border-radius: 3px;

  & a {
    display: flex;
    align-items: center;

    padding: 0 0 0 1rem;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: $title;
    transition: 300ms ease;
    border-bottom: 1px solid $separation-border;
    font-size: 0.77rem;

    &:last-child {
      border-bottom: initial;
    }

    &:hover {
      background: $primary-hover;
    }
  }
}

// Services dropdown
.services {
  user-select: none;
}

// Dropdown
.services-dropdown {
  display: flex;
  flex-direction: column;

  position: absolute;
  margin: 1.3rem 0 0 0;
  min-width: max-content; /* Set the minimum width to fit the content */
  white-space: nowrap; /* Prevent line breaks */
  z-index: 501;
  left: 0;

  background: $primary;
  cursor: initial;
  box-shadow: -5px 5px 27px rgb(0, 0, 0, 0.12);
  border-radius: 3px;

  // Arrow up
  &::before {
    content: '';
    position: absolute;
    width: 1.3rem;
    height: 1.3rem;
    transform: rotate(45deg);
    top: -0.5rem;
    margin: 0 0 0 2.72rem;
    z-index: -1;

    background: $primary;
  }

  & a {
    display: flex;
    align-items: center;

    height: 2.7rem;
    text-decoration: none;
    color: $title;
    transition: 300ms ease;
    border-bottom: 1px solid $separation-border;
    font-size: 0.77rem;

    &:last-child {
      border-bottom: initial;
    }

    &:hover {
      background: $primary-hover;
    }
  }
}

// Sell cta
.sell-cta-text {
  text-decoration: none;
  color: $btn-text;
  
  padding: 0.62rem 1.42rem;
  margin: 0 0 0 0;

  font-size: 0.82rem;
  border-radius: 50px;
  color: $primary;
  font-weight: 400;
  background: $title;

  &:hover {
    background: $accent-hover;
  }
}