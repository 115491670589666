@import '../../../assets/scss/variables.scss';

// Menu
.menu {
  width: 0;
  height: 0;
  position: fixed;
  top: -5rem;
  right: -5rem;
  z-index: 12000;

  background: $primary-darker;
  border-radius: 50%;
  transition: all 400ms;
}

// Open state of the menu
.menu-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  padding: 1rem 1rem 1.8rem 1rem;
  height: 100%;
  
  opacity: 0;
  transition: 300ms ease-in;
  transition-delay: -1s;
}

.menu-content-visible {
  opacity: 100%;

  transition: 300ms ease-in;
  transition-delay: 0.4s;
}

.open {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  top: 0;
  right: 0;

  border-radius: 0;
}

// Top
.top {
  display: flex;
  align-items: center;

  width: 100%;

  // Close
  & h5 {
    font-size: 0.99rem;
    font-weight: 600;
    text-decoration: underline;
    color: $title;
  }

  & svg {
    margin: 1px 0.5rem 0 0;
    transform: rotate(180deg);
    scale: 1.14;

    & path {
      stroke: $second;
    }
  }
}

.title {
  display: flex;
  align-items: center;

  & h2 {
    margin: 0 0 0 0.12rem;

    font-family: $general-font;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.33rem;
    color: $accent;
  }

  & img {
    margin: 0 0 0 -5px;
  }
}

// Links
.links {
  margin: 3rem 0 0 0;

  & li {
    margin: 0.67rem 0 0 0;
    position: relative;
    width: fit-content;

    font-size: 1.25rem;
    list-style-type: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 15%;
      height: 2px;

      border-radius: 1rem;
      background: $text;
    }
  }

  & a {
    color: $title;
    text-decoration: none;
  }
}