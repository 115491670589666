@import '../../assets/scss/variables.scss';

// Call to action
.cta {
  display: flex;
  align-items: center;

  width: fit-content;
  padding: 0.47rem 1.2rem 0.42rem 1.2rem;
  margin: 1.9rem 0 0;

  border-radius: 50px;
  font-weight: 500;
  font-size: 1rem;
  background: $accent;
  color: $btn-text;
  text-decoration: none;
  font-size: 0.88rem;
  cursor: pointer;
  white-space: nowrap;

  & img {
    margin: -1px 0 0 0.67rem;
    width: 0.467rem;
  }
}
