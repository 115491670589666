@import '../../assets/scss/variables.scss';

.illustration {
  padding: 5.5rem 0 0 0;
}

// Content
.content {
  padding: 0 $padding 1.5rem $padding;

  // Sub Title
  & h3 {
    @include sub;
  }

  // Title
  & h2 {
    margin: 0 6rem 0 0;

    @include titles;
  }

  // Text
  & p {
    margin: 1.2rem 2rem 0 0;

    @include text;
  }

  // Illustration
  & .graph {
    width: 100%;
  }
}

@media all and (min-width: 1025px) {
  .cont {
    @include center;
  }

  .illustration {
    display: grid;
    grid-template-columns: 48% 52%;

    width: $width;
    padding: 7.7rem $padding2 8rem $padding2;
  }
  
  // Content
  .content {
    padding: 0 0 1.5rem 0;
  
    // Sub Title
    & h3 {
      @include sub2;
    }
  
    // Title
    & h2 {
      margin: 0 6rem 0 0;
  
      @include titles2;
    }
  
    // Text
    & p {
      margin: 1.2rem 2rem 0 0;
  
      @include text2;
    }
  }

  // Desktop images
  .img-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
  }

  .graph1 {
    width: 35rem;

    box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
  }

  .graph2 {
    position: absolute;
    width: 13rem;
    left: 0;
    top: 4rem;

    box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
  }

}