@import '../../assets/scss/variables.scss';

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 4.6rem;
  padding: 0 1rem;

  background: $primary;
}

// Title
.title {
  display: flex;
  align-items: center;
  text-decoration: none;

  & h2 {
    margin: 0 0 0 0.12rem;

    font-family: $general-font;
    font-weight: 500;
    font-size: 1.33rem;
    color: $accent;
  }

  & img {
    margin: 0 0 0 -5px;
  }
}

// Menu
.menu {
  padding: 1.4rem 0 1.4rem 0.6rem;
}

.menu-line {
  width: 1.96rem;
  height: 2px;
  position: relative;

  background: $second;
  border-radius: 2rem;

  &::before {
    content: '';
    position: absolute;
    width: 1rem;
    height: 2px;
    transform: translateY(-7.25px);
    right: 0;

    background: $second;
    border-radius: 2rem;
  }

  &::after {
    content: '';
    position: absolute;
    width: 1.4rem;
    height: 2px;
    transform: translateY(7.25px);
    right: 0;

    background: $second;
    border-radius: 2rem;
  }
}

// Right side
.right {
  display: flex;
  align-items: center;
}

.lang {
  // Current language
  & h5 {
    margin: -6px 0.35rem 0 0;
    position: relative;

    font-size: 1.11rem;
    font-weight: 500;
    color: $title;
    font-family: $general-font;

    &::before {
      content: '';
      position: absolute;
      width: 96%;
      height: 2px;
      bottom: -1.2px;
      margin-left: 0.5px;

      background: $title;
    }
  }
}

// Phone icon - call
.call {
  margin: 0 1.1rem 0 0;
  width: 1.3rem;
}

// Dropdown
.dropdown-inner {
  display: flex;
  flex-direction: column;
  
  position: relative;
  width: 100%;
  height: 100%;
}

.dropdown {
  width: 100%;
  position: fixed;
  top: -40%;
  left: 0;
  z-index: 700;
  
  background: $primary;
  box-shadow: 0 0 10px 9999px rgba(0, 0, 0, 0);
  transition: ease 300ms;

  & a {
    width: 100%;
    position: relative;
    padding: 1.2rem 0 1.6rem 1.3rem;

    border-bottom: 1.5px solid $separation-border;
    color: $title;
    font-weight: 500;
    text-decoration: none;
    font-size: 1.15rem;
    
    &::before {
      content: '';
      position: absolute;
      width: 1.6rem;
      height: 1.5px;
      bottom: 22px;
      margin-left: 0.5px;

      background: $title;
    }


    &:last-child {
      border-bottom: none;
    }
  }

  // CLOSE
  .lang-close {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 2.69rem;
    min-height: 2.69rem;
    position: absolute;
    right: 0.7rem;
    bottom: -1.35rem;

    background: $accent;
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      width: 1.2rem;
      height: 2px;
      transform: rotate(43deg);

      border-radius: 15px;
      background: #fff;
    }

    &::after {
      content: '';
      position: absolute;
      width: 1.2rem;
      height: 2px;
      transform: rotate(-43deg);

      border-radius: 15px;
      background: #fff;
    }
  }
}

.dropdown-open {
  top: 0 !important;
  box-shadow: 0 0 10px 9999px rgba(0, 0, 0, 0.5);
}

// Desktop view
@media all and (min-width: 1025px) {
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    height: 4.6rem;
    width: $width;
    padding: 0 $padding2;
    position: fixed;
    top: 0;
    left: initial;
    z-index: 500;
  
    background: initial;
  }

  .cont {
    display: flex;
    align-items: center;
    justify-content: center;
  
    height: 4.6rem;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;

    background: $primary;
  }
  
  // Title
  .title {
    display: flex;
    align-items: center;
    text-decoration: none;
  
    & h2 {
      margin: 0 0 0 0.12rem;
  
      font-family: $general-font;
      font-weight: 500;
      font-size: 1.33rem;
      color: $accent;
    }
  
    & img {
      margin: 0 0 0 -5px;
    }
  }
  
  // Menu
  .menu {
    padding: 1.4rem 0 1.4rem 0.6rem;
  }
  
  .menu-line {
    width: 1.96rem;
    height: 2px;
    position: relative;
  
    background: $second;
    border-radius: 2rem;
  
    &::before {
      content: '';
      position: absolute;
      width: 1rem;
      height: 2px;
      transform: translateY(-7.25px);
      right: 0;
  
      background: $second;
      border-radius: 2rem;
    }
  
    &::after {
      content: '';
      position: absolute;
      width: 1.4rem;
      height: 2px;
      transform: translateY(7.25px);
      right: 0;
  
      background: $second;
      border-radius: 2rem;
    }
  }
  
  // Right side
  .right {
    display: flex;
    align-items: center;
  }
  
  .lang {
    // Current language
    & h5 {
      margin: -6px 0.35rem 0 0;
      position: relative;
  
      font-size: 1.11rem;
      font-weight: 500;
      color: $title;
      font-family: $general-font;
  
      &::before {
        content: '';
        position: absolute;
        width: 96%;
        height: 2px;
        bottom: -1.2px;
        margin-left: 0.5px;
  
        background: $title;
      }
    }
  }
  
  // Phone icon - call
  .call {
    margin: 0 1.1rem 0 0;
    width: 1.3rem;
  }
  
  // Dropdown
  .dropdown-inner {
    display: flex;
    flex-direction: column;
    
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .dropdown {
    width: 100%;
    position: fixed;
    top: -40%;
    left: 0;
    z-index: 700;
    
    background: $primary;
    box-shadow: 0 0 10px 9999px rgba(0, 0, 0, 0);
    transition: ease 300ms;
  
    & a {
      width: 100%;
      position: relative;
      padding: 1.2rem 0 1.6rem 1.3rem;
  
      border-bottom: 1.5px solid $separation-border;
      color: $title;
      font-weight: 500;
      text-decoration: none;
      font-size: 1.15rem;
      
      &::before {
        content: '';
        position: absolute;
        width: 1.6rem;
        height: 1.5px;
        bottom: 22px;
        margin-left: 0.5px;
  
        background: $title;
      }
  
  
      &:last-child {
        border-bottom: none;
      }
    }
  
    // CLOSE
    .lang-close {
      display: flex;
      align-items: center;
      justify-content: center;
  
      min-width: 2.69rem;
      min-height: 2.69rem;
      position: absolute;
      right: 0.7rem;
      bottom: -1.35rem;
  
      background: $accent;
      border-radius: 50%;
  
      &::before {
        content: '';
        position: absolute;
        width: 1.2rem;
        height: 2px;
        transform: rotate(43deg);
  
        border-radius: 15px;
        background: #fff;
      }
  
      &::after {
        content: '';
        position: absolute;
        width: 1.2rem;
        height: 2px;
        transform: rotate(-43deg);
  
        border-radius: 15px;
        background: #fff;
      }
    }
  }
  
  .dropdown-open {
    top: 0 !important;
    box-shadow: 0 0 10px 9999px rgba(0, 0, 0, 0.5);
  }
}